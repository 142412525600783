<template>
    <v-app-bar app dense>
        <v-btn exact outlined to="/dashboard">
            Проекты
        </v-btn>
        <v-btn
            v-if="this.$store.getters.isAdmin"
            class="mr-4"
            outlined
            to="/admin"
        >
            Администрирование
        </v-btn>
        <v-spacer />
        <v-app-bar-title class="justify-center mx-2" v-html="projectName" />
        <v-spacer />
        <v-row
            align="center"
            class="shrink my-0 flex-nowrap"
            justify="space-between"
        >
            <v-tooltip
                v-if="$store.getters.version === 'old'"
                bottom
                color="black"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="mx-1"
                        color="warning"
                        icon
                        @click="openUpdater"
                    >
                        <v-icon large>
                            mdi-alert
                        </v-icon>
                    </v-btn>
                </template>
                <span>
                    <b class="warning--text"
                        >Вы используете устаревшую версию!</b
                    >
                </span>
            </v-tooltip>
            <about />
            <v-btn class="mx-1" outlined @click="logout">
                Выход
            </v-btn>
        </v-row>
    </v-app-bar>
</template>
<script>
import UPDATE_NOTIFICATION_SET from "@/store/actions/updater";
import { AUTH_LOGOUT } from "../store/actions/auth";
import About from "@/views/About";

export default {
    components: { About },
    methods: {
        logout() {
            this.$store.dispatch(AUTH_LOGOUT).then(() => {
                if (window.AppClose) {
                    window.AppClose();
                } else {
                    this.$router.push("/");
                }
            });
        },
        openUpdater() {
            this.$store.commit(UPDATE_NOTIFICATION_SET, true);
        }
    },
    computed: {
        projectName() {
            return this.$store.getters.projectName !== ""
                ? `Проект <b>"${this.$store.getters.projectName}"</b>`
                : "";
        }
    }
};
</script>
